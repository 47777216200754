<template>
  <a-affix :offset-top="top">
    <div class="nav-box flex-between">
      <div>寄快递 发物流丨就上人人寄</div>
      <div class="mobile-box">
        <img class="more" v-if="!isShowMenu" @click="handleMenu" src="../assets/images/more1.png" />
        <img class="more" v-if="isShowMenu" @click="handleMenu" src="../assets/images/close.png" />
      </div>
      <div class="nav-btn-box" v-if="isShowMenu">
        <div class="nav-btn">
          <p @click="handleDom(1)">关于我们</p>
          <p @click="handleDom(2)">服务优势</p>
          <p @click="handleDom(3)">联系我们</p>
        </div>
      </div>
    </div>
  </a-affix>
  <div class="screen1 page">
    <div class="content flex-col-center">
      <div class="content-title">
        <div>寄快递发物流 | 就上人人寄</div>
        <div>AI自动多渠道比价，寄快递发物流，超能帮你省</div>
        <div class="qrCode">
          <a-button class="nav-login" type="primary" @click="handleOpen1" danger>立即寄件</a-button>
        </div>
      </div>
      <img src="../assets/images/index1_1.png" alt="">
    </div>
  </div>

  <!-- 底部弹框 -->
  <div class="modal-overlay" v-if="isShow1" @click.self="handleClose1">
    <div class="modal modal-bottom">
      <img class="close-btn top-right" @click="handleClose1" src="../assets/images/Off.png" alt="关闭按钮" />
      <div class="modal-content">
        <div class="modal-text">
          <p class="modal-title">长按保存图片</p>
          <p class="modal-subtitle">去微信扫码领取首单优惠</p>
        </div>
        <img class="qr-code" src="../assets/images/qrcode_for_gh_78878e4ec3b4_344 (2).jpg" alt="二维码" />
      </div>
    </div>
  </div>
  <div class="screen3 " id="mine">
    <div class="content">
      <div class="yibuFrameContent flex-col-center">
        <img src="../assets/images/img1.png" alt="">
        <h2>全国取件</h2>
        <p>支持全国各地上门取件，解决客户异地发快递难题；16:00之前下单，当日取件，16:00之后下单，次日取件；全年无休</p>
      </div>
      <div class="yibuFrameContent flex-col-center">
        <img src="../assets/images/img2.png" alt="">
        <h2>价格优惠</h2>
        <p>平台直接签约快递总部，价格极具竞争力；并通过AI人工智能比价系统，自动推荐市面上价格最优惠的快递</p>
      </div>
      <div class="yibuFrameContent flex-col-center">
        <img src="../assets/images/img3.png" alt="">
        <h2>下单便捷</h2>
        <p>支持微信公众号和微信小程序下单，操作简单，便捷易用，快递、同城急送、大件物流均可轻松搞定</p>
      </div>
      <div class="yibuFrameContent flex-col-center">
        <img src="../assets/images/img4.png" alt="">
        <h2>客户服务</h2>
        <p>客服在线时间9:00--23:00，全年无休，随时响应并处理客户寄快递过程中遇到的问题，做到专业、细致、耐心</p>
      </div>
      <div class="yibuFrameContent flex-col-center">
        <img src="../assets/images/img5.png" alt="">
        <h2>安全保障</h2>
        <p>直接对接快递公司总部，所有包裹均为快递公司小哥直接取件派件，物流轨迹全程可追踪，安全可靠</p>
      </div>
      <div class="yibuFrameContent flex-col-center">
        <img src="../assets/images/img6.png" alt="">
        <h2>选择多样</h2>
        <p>平台对接市面上所有主流快递公司，不仅价格优惠，还有丰富的资源可供选择，能满足您的多样化需求</p>
      </div>
    </div>
  </div>
  <div class="screen2 ">
    <div class="content ">
      <img class="phone-img" src="../assets/images/about-preview.png" alt="">
      <div class="text-box">
        <div class="title">个人/中小商家寄件的好选择</div>
        <h3>无单量限制，一单也是特惠价<br />支持寄国内，寄大件，寄物流</h3>
        <div class="text">
          24小时在线下单，快递员上门取件<br />
          支持全国主流快递物流公司<br />
          自主灵活选择快递渠道发货，运费低至5元起
        </div>
      </div>
    </div>
  </div>
  <div class="screen4 ">
    <div class="content">
      <h2>我们的服务能力</h2>
      <div class="power-list">
        <div class="power-item flex-col-center">
          <img src="../assets/images/power1.png" />
          <h4>当日取件</h4>
          <p>在线下单，<br />全国省市区上门取件，一般当天<br />取件时效48小时</p>
        </div>
        <div class="power-item flex-col-center">
          <img src="../assets/images/power2.png" />
          <h4>运输时效</h4>
          <p>国内主流快递品牌运输<br />支持圆通、申通、韵达、极兔、德邦、顺丰等一线<br />品牌快递，时效有保障！</p>
        </div>
        <div class="power-item flex-col-center">
          <img src="../assets/images/power3.png" />
          <h4>推广奖励</h4>
          <p>分享好友<br />分享好友寄快递，单单得奖励！</p>
        </div>
      </div>
    </div>
  </div>
  <div class="screen5 " id="advantage">
    <div class="content">
      <h2>我们的优势</h2>
      <div class="feature-box flex-between">
        <div class="feature-item flex-col-left">
          <div class="iconfont">
            <img src="../assets/images/feature1.png" />
          </div>
          <h4>寄快递</h4>
          <p>1-30kg小件快递<br />可自主选择快递渠道智能比价<br />低至5元起寄！</p>
        </div>
        <div class="feature-item flex-col-left">
          <div class="iconfont">
            <img src="../assets/images/feature4.png" />
          </div>
          <h4>推广奖励</h4>
          <p>一件也是特惠价<br />分享好友寄件，奖励多多<br />一次分享，永久绑定，搭建管道收益</p>
        </div>
        <div class="feature-item flex-col-left">
          <div class="iconfont">
            <img src="../assets/images/feature2.png" />
          </div>
          <h4>寄物流</h4>
          <p>20-5000Kg 大件物流<br />顺心捷达、跨越、德邦、京东物流、中通全渠道覆盖
            <br />当天上门取件，时效快，价格优!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer page " id="about">
    <h4>联系我们</h4>
    <div class="content">
      <p>如有任何疑问请联系我们，我们竭诚为您服务<br />
        客服时间 周一至周日 9：00 至 23：00<br />
        联系邮箱 bd@cpskd.cn</p>
    </div>
    <div class="flex">
      <div class="code flex-col-center">
        <img src="../assets/images/qrcode1.jpg" />
        <p>公众号</p>
      </div>
      <div class="code flex-col-center">
        <img src="../assets/images/qrcode1_2.png" />
        <p>小程序</p>
      </div>
      <div class="code flex-col-center">
        <img src="../assets/images/qrcode1_3.png" />
        <p>微信</p>
      </div>
    </div>
    <a-divider />
    <div class="footer-text">湖南耕耘未来科技有限公司 版权所有 Copyright © 2024 <a href="https://beian.miit.gov.cn"
        target="_blank">湘ICP备2023025772号-2</a> </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const isShowMenu = ref(false)
const top = ref(0)
const isShow1 = ref(false)

const handleMenu = () => {
  isShowMenu.value = !isShowMenu.value
}

const handleDom = (index) => {
  if (index === 1) {
    document.querySelector("#mine").scrollIntoView(true);
  } else if (index === 2) {
    document.querySelector("#advantage").scrollIntoView(true);
  } else if (index === 3) {
    document.querySelector("#about").scrollIntoView(true);
  }
  isShowMenu.value = false
}

const handleOpen1 = () => {
  isShow1.value = true
  document.body.style.overflow = 'hidden'
  document.documentElement.style.overflow = 'hidden'
}

const handleClose1 = () => {
  isShow1.value = false
  document.body.style.overflow = 'auto'
  document.documentElement.style.overflow = 'auto'
}
</script>
<style scoped lang="scss">
@import url('../assets/style/index.scss');

/*底部弹框二维码开始*/
.modal-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30vh;
  background: url('../assets/images/BgPic.png') no-repeat center;
  background-size: cover;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.modal-content {
  position: relative;
  text-align: center;
}

.close-btn.top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.qr-code {
  width: 150px;
  height: 150px;
}

.modal-text {
  margin-bottom: 10px;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.modal-subtitle {
  font-size: 16px;
  color: #000000;
}
/*底部弹框二维码结束*/



.nav-box {
  background-color: #E6F0FF;
  width: 100%;
  height: 60px;
  padding: 20px;
  box-sizing: border-box;

  .logo {
    width: 100px;
  }

  .mobile-box {
    img {
      width: 25px;
    }
  }

  .nav-btn-box {
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 999;
    background: #E6F0FF;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    .nav-login {
      width: 78px;
      height: 28px;
      background: #2c3cfe;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 500;
      color: #FFFFFF;
    }

    .nav-btn {
      .qrCode {
        position: relative;

        .codeBg {
          width: 230px;
          height: 260px;
          position: absolute;
          top: 0;
          left: -40px;
          z-index: 99;
          background: url('../assets/images/codeBg.png');
          background-size: 100% 100%;

          img {
            width: 113px;
            height: 113px;
            margin: auto;
            display: block;
            margin-top: 72px;
          }
        }


      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: #4F5361;
        cursor: pointer;
      }
    }
  }
}

.qrCode {
  position: relative;

  .codeBg {
    width: 230px;
    height: 260px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    background: url('../assets/images/codeBg.png');
    background-size: 100% 100%;

    img {
      width: 113px;
      height: 113px;
      margin: auto;
      display: block;
      margin-top: 72px;
    }
  }
}

.footer-text {
  font-size: 12px;
  text-align: center;
  color: #666;
}

.footer {
  background-color: #eceefd;
  width: 100%;

  h4 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;
  }

  .content {
    p {
      margin-bottom: 20px;
      font-size: 12px;
      color: #222222;
      line-height: 20px;
    }
  }

  .code {
    margin-right: 30px;

    img {
      width: 80px;
    }

    p {
      font-size: 12px;
      color: #222222;
      line-height: 20px;
    }
  }
}

.screen5 {
  width: 100%;
  background-color: #f5f7fe;

  .content {
    padding: 40px 20px;
    box-sizing: border-box;
    background: url('../assets/images/bg3_2.png') no-repeat;
    width: 100%;
    background-size: 40% auto;
  }

  h2 {
    text-align: center;
    font-size: 28px;
  }

  .feature-box {
    margin-top: 20px;
    flex-wrap: wrap;

    .feature-item:nth-child(3) {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      height: auto;
    }

    .feature-item {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 6px;
      width: 48%;
      height: 200px;
      border: 1px solid #ddd;
      box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.1);
      padding: 10px;

      .iconfont {
        width: 40px;
        height: 40px;
        border: 1px solid #2c3cfe;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($color: #e1e4ff, $alpha: .6);
      }

      img {
        width: 25px;

      }

      h4 {
        margin: 10px 0 15px;
        font-size: 16px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        color: #222222;

      }
    }
  }
}

.screen4 {
  width: 100%;
  background-color: #eceefd;

  .content {
    padding: 20px;
    box-sizing: border-box;

    h2 {
      text-align: center;
      font-size: 28px;
    }
  }

  .power-list {
    margin-top: 20px;

    .power-item {

      img {
        width: 200px;
        margin-top: 20px;
      }

      h4 {
        margin: 10px 0 20px;
        font-size: 18px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #222222;
      }
    }
  }

}

.screen3 {
  width: 100%;
  background-color: #eceefd;


  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    background: url('../assets/images/bg4_1.png') no-repeat;
    width: 100%;
    background-size: 100% auto;


    .yibuFrameContent {
      width: 48%;
      margin-top: 20px;
      padding: 20px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
      border-radius: 6px;

      img {
        width: 30px;
        height: 30px;
      }

      h2 {
        font-size: 18px;
        margin: 10px 0 20px 0;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}

.screen2 {
  width: 100%;
  background-color: #f5f8fe;

  .content {
    padding: 20px;
    box-sizing: border-box;
    background: url('../assets/images/bg2_1.png') no-repeat;
    width: 100%;
    background-size: 100% auto;
  }

  .phone-img {
    width: 60%;
    display: block;
    margin: 20px auto;
  }

  .text-box {

    .title {
      font-size: 14px;
    }

    h2 {
      font-size: 24px;
      margin: 20px 0;
    }

    .text {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.screen1 {
  background-color: #f5f8fe;

  .nav-login {
    width: 98px;
    height: 36px;
    background: #2c3cfe;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    display: block;
    margin: 20px auto;
  }

  .content {
    margin-top: 30px;

    .content-title {
      div {
        font-size: 26px;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    img {
      width: 60%;
    }
  }
}

.page {
  padding: 20px;
  box-sizing: border-box;
}
</style>
